import { Row, Col, Form, Button, Container, Spinner } from 'react-bootstrap'
import { useState, useEffect, useRef } from 'react'
import _isEmpty from 'lodash/isEmpty'
import axios from '@/support/utils/request'
import _kebabCase from 'lodash/kebabCase'

import { useHistory } from 'react-router'
import moment from 'moment'
import { views } from '../../../helpers/appViews'
import Error from '../../Error/Error'
import './ClaimCredentials.scss'
import config from '@/config'

export default function ClaimCredentials(props) {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [storeOrderId, setStoreOrderId] = useState('')
  const [fromEmail, setFromEmail] = useState(false)
  const [error, setError] = useState({
    active: false,
    message: 'Default',
  })
  const [emailValidated, setEmailValidated] = useState(false)
  const [storeOrderValidated, setStoreOrderValidated] = useState(false)
  const storeOrderInputRef = useRef(null)

  const proxy = config.api.url

  useEffect(() => {
    // Checks the URL parameters to see if a store ID and email were provided for a claim (this would come from the order confirmation page)
    checkUrlParameters()
  }, [])

  const getClaimTypes = async () => {
    try {
      const claimTypes = await axios.get(`${proxy}/claimTypes`)
      return claimTypes.data
    } catch (e) {
      setError({
        active: true,
        message: e.response.data,
      })
    }
  }

  const getOrderItems = async (orderProtectionId) => {
    try {
      const orderItems = await axios.get(`${proxy}/stores/orders/${orderProtectionId}/items`)

      let itemsInOrder = orderItems.data

      return itemsInOrder
    } catch (e) {
      setError({
        active: true,
        message: e.response.data,
      })
    }
  }

  const getClaimItems = async (claim_id, order_id) => {
    try {
      const claimItems = await axios.get(`${proxy}/claims/${claim_id}/${order_id}/items`)

      let itemsInClaim = claimItems.data

      return itemsInClaim
    } catch (e) {
      setError({
        active: true,
        message: e.response.data,
      })
    }
  }

  const getClaimStatus = async (claim_id) => {
    try {
      const claimStatus = await axios.get(`${proxy}/claims/${claim_id}/status`)
      return claimStatus.data.status_name
    } catch (e) {
      setError({
        active: true,
        message: e.response.data,
      })
    }
  }

  async function handleClaimSubmit() {
    if (_isEmpty(email) || _isEmpty(storeOrderId)) {
      setEmailValidated(_isEmpty(email))
      setStoreOrderValidated(_isEmpty(storeOrderId))
    } else {
      setIsLoading(true)
      setEmailValidated(false)
      setStoreOrderValidated(false)

      try {
        // A storeOrderId may include string prefix and sufix which will be handled backend
        const data = {
          email,
          storeOrderId,
        }
        var order = await axios.post(`${proxy}/claims/credentials/verify`, data)
        const orderStatus = order.status
        if (orderStatus === 200) {
          getClaimTypes().then((claimTypes) => {
            if (claimTypes) {
              getOrderItems(order.data.protection_order_id).then(async (orderItems) => {
                // If no claim have been filed, no order item has been checked yet
                orderItems.forEach(function (elm, i) {
                  orderItems[i] = { ...elm, is_checked: false }
                })

                var store = order.data.store_id
                const storeId = {
                  store,
                }
                var storeCredentials = await axios.post(
                  `${proxy}/claims/storeCredentials/response`,
                  storeId
                )

                order.data.orderItems = orderItems

                let fulfillments = []
                const platformId = order?.data?.platform_id
                if (platformId.isShopify()) {
                  fulfillments = await axios.post(
                    `${proxy}/shopify/orders/${order.data.shopify_order_id}/fulfillment`,
                    storeCredentials.data
                  )
                } else if (platformId.isMagento()) {
                  fulfillments = await axios.get(
                    `${proxy}/magento-proxy/${store}/orders/${order.data.order_id}/item-details`
                  )
                }

                const fulfillmentData = fulfillments?.data || null
                order.data.orderItems = orderItems
                var STA = false
                var waitUntilDate = {}

                if (!_isEmpty(fulfillmentData) && platformId === 1) {
                  for (var i = 0; i < fulfillmentData.fulfillments.length; i++) {
                    for (var j = 0; j < fulfillmentData.fulfillments[i].line_items.length; j++) {
                      var item = orderItems.find(
                        (element) =>
                          Number(element.product_id) ===
                          fulfillmentData.fulfillments[i].line_items[j].product_id
                      )
                      if (item != undefined) {
                        const fulfillmentDate = fulfillmentData?.fulfillments[i]?.updated_at
                        if (
                          fulfillmentData?.fulfillments[i]?.shipment_status?.toLowerCase() ==
                          'delivered'
                        ) {
                          const today = new Date()
                          var diff = moment(today).diff(fulfillmentDate, 'days')
                          if (diff < 2) {
                            STA = true
                            waitUntilDate = await moment(fulfillmentDate).add(2, 'days').toDate()
                          }
                        }
                      }
                    }
                  }
                }

                props.updateClaimWaitData({
                  status: STA,
                  formattedWaitDate: waitUntilDate.toLocaleString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  }),
                })

                props.setState({
                  ...props.state,
                  customerData: order.data,
                  claimTypes: claimTypes,
                  store: storeCredentials.data,
                  fulfillments: fulfillmentData,
                })

                setIsLoading(false)
                props.changeView(views.CLAIMTYPE)
              })
            }
          })
        }
        if (orderStatus === 202) {
          getClaimTypes().then((claimTypes) => {
            if (claimTypes) {
              getClaimStatus(order.data.claim_id).then((claimStatus) => {
                //this is new fetched so order.data.id should be claims_id
                if (claimStatus) {
                  getClaimItems(order.data.claim_id, order.data.id).then((orderItems) => {
                    order.data.orderItems = orderItems
                    order.data.claimStatus = claimStatus

                    props.setState({
                      ...props.state,
                      customerData: order.data,
                      claimTypes: claimTypes,
                    })
                    props.changeView(views.CLAIMCONFIRMATION)
                  })
                }
              })
            }
          })
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        setError({
          active: true,
          message: e.response.data,
        })
      }
    }
  }

  const checkUrlParameters = () => {
    const search = window.location.search

    if (search) {
      const params = new URLSearchParams(search)
      let storeOrderId = params.get('op_id')
      const email = decodeURIComponent(params.get('email', ''))
      const fromEmail = params.get('fromEmail')

      setStoreOrderId(storeOrderId)
      setEmail(email)
      setFromEmail(fromEmail?.toString() === 'true')
    }
  }

  const handleEnterKeyDown = (event) => {
    if (event.key === 'Enter' && _isEmpty(email) === false && _isEmpty(storeOrderId) === false) {
      handleClaimSubmit()
    }
  }

  const history = useHistory()

  useEffect(() => {
    if (fromEmail === true && _isEmpty(email) === false && _isEmpty(storeOrderId) === false) {
      handleClaimSubmit()
      history.push('/submitClaim')
    }
  }, [fromEmail, email, storeOrderId])

  useEffect(() => {
    storeOrderInputRef.current.focus()
  }, [])

  return (
    <div className="body-submit-claim" onKeyDown={handleEnterKeyDown} tabIndex="0">
      <div className="inner">
        <Container>
          <Row className="content-submit-claim">
            <Col md={12}>
              <Form className="form-submit-claim">
                <h3 className="title claims-heading">Get a resolution for your order in minutes</h3>
                <p className="sub-text">
                  We’re sorry to hear you have a problem with your order. Let’s get it resolved.
                </p>
                <Error error={error} />

                <Form.Group controlId="formBasicOrderID">
                  <Form.Label>
                    Order Number <span className="color-required">*</span>
                  </Form.Label>
                  <Form.Control
                    ref={storeOrderInputRef}
                    type="text"
                    value={storeOrderId}
                    onChange={(event) => {
                      setStoreOrderId(event.target.value)
                    }}
                    isInvalid={storeOrderValidated}
                    className={storeOrderValidated ? 'red-border' : null}
                  />
                  <Form.Control.Feedback className="error-text" type="invalid">
                    Enter your order number
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>
                    Email Address <span className="color-required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value)
                    }}
                    onKeyDown={handleEnterKeyDown}
                    isInvalid={emailValidated}
                    className={emailValidated ? 'red-border' : null}
                  />
                  <Form.Control.Feedback className="error-text" type="invalid">
                    Enter your order email address
                  </Form.Control.Feedback>
                </Form.Group>
                <Button
                  variant="primary"
                  className="btn-submit"
                  onClick={() => {
                    handleClaimSubmit()
                  }}
                >
                  {isLoading === true ? (
                    <Spinner size="sm" className="ml-2" animation="border" variant="light" />
                  ) : (
                    <span>Continue</span>
                  )}
                </Button>
              </Form>
            </Col>
            {/* <Col md={6}>
            <div className="content-right">
              <div className="wrap-image">
                <img className="image" src={ClaimPreIdentified} alt="Claim Pre Identified" />
              </div>
            </div>
                </Col> */}
          </Row>
        </Container>
      </div>
    </div>
  )
}
